@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

[data-bs-theme="dark"] {
    --bs-body-color: rgb(255, 255, 255) !important; /* Change text color to black */
    --bs-body-bg: black; /* Change background color to dark green */
}
body {background-color: #000 !important;margin: auto;  font-family: "Poppins", sans-serif;}
#input-buttons .kp-calculadora-button {
    display: inline-block;
    vertical-align: top;
    width: 22px;
    height: 19px;
    padding: 10px;
    margin: 5px 1px;
    border-radius: 5px;
    background: #42454a;
    background: -ms-linear-gradient(top, #42454a 0%, #313039 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#42454a',
        endColorstr='#313039',
        GradientType=0
      );
    background: linear-gradient(to top, #42454a 0%, #313039 100%);
    box-shadow: 0 1px 3px 1px #000;
    font: 20px "Consolas", "Monaco", monospace;
    text-align: center;
    color: rgba(149, 136, 252, 1);
    text-shadow: 0px 1px 1px #000;
    cursor: pointer;
  }
  
  #input-buttons .kp-calculadora-button:active {
   opacity: 0.8;
  }
  
  #input-buttons .kp-num {
    background: #5a656b;
    background: -ms-linear-gradient(to top, #5a656b 0%, #4a5152 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#5a656b',
        endColorstr='#4a5152',
        GradientType=0
      );
    background: linear-gradient(to top, #5a656b 0%, #4a5152 100%);
  }
  
  #input-buttons .kp-num:active {
    background: linear-gradient(to top, #5a656b 0%, #4a5152 38%);
  }
  
  #input-buttons .kp-eql {
    background: #ff9600;
    background: -ms-linear-gradient(top, #ff9600 0%, #9c4100 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#ff9600',
        endColorstr='#9c4100',
        GradientType=0
      );
    background: linear-gradient(to top, #ff9600 0%, #9c4100 100%);
  }
  
  #input-buttons .kp-eql:active {
    background: linear-gradient(to top, #ff9600 0%, #9c4100 38%);
  }
  
  #input-buttons .kp-back {
    background: #426184;
    background: -ms-linear-gradient(top, #426184 0%, #29415a 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#426184',
        endColorstr='#29415a',
        GradientType=0
      );
    background: linear-gradient(to top, #426184 0%, #29415a 100%);
  }
  
  #input-buttons .kp-back:active {
    background: linear-gradient(to top, #426184 0%, #29415a 38%);
  }
  
  #input-buttons .kp-4letters {
    font: bold 15px "Consolas", "Monaco", monospace !important;
    padding: 12px 10px 8px 10px;
  }
  
  #input-buttons .kp-5letters {
    padding: 12px 12px 8px 8px;
    font: bold 15px "Consolas", "Monaco", monospace !important;
  }
  
  #input-buttons .kp-calculadora-button,
  #input-buttons .kp-4letters {
    color: #fff;
    width: 65px;
    height: 50px;
  }

#input-buttons .kp-calculadora-button, #input-buttons .kp-4letters { color: #fff;width: 65px;height: 50px;}
#input{width: 100%; padding: 25px; margin: 15px; background-color: rgba(37, 37, 37, 1); border-radius: 50px;border-top:2px solid #595959 ;border-left:2px solid #595959 ;color: #fff;}
#input-buttons .kp-num {background: #fff; color: #000; }
#input-buttons .kp-num.kp-2 {background: #515151;color: #fff;}
#input-buttons .kp-eql { background: #1D4EC7;}
#input-buttons .kp-back {background:rgba(255, 0, 0, 0.33) ; }
.navbar-theme-color {background-color:#29264D ;}
.navbar-theme-color a {color:#fff !important;}
.navbar-theme-color a:active {color: rgb(223, 223, 223) !important;}
.box-funtion-3 {width: 311px; height: 119; padding: 25px 20px; background: #252525;margin: 15px;}
.box-funtion-3 p { font-weight: 500; font-size: 16px; line-height: 24px;margin-bottom: 1px !important;color: #FF6969;}
.container-calcu-online {padding: 0px 150px;}
.formula_para p{color:#fff;text-align: center; font-weight: 500; line-height: 30px; margin-bottom: 2px;font-size: 20px;}
@media (max-width: 768px) { .div1 { display: none; } .div2 { display: block;padding: 0px 10px;text-align: center; }}
.div1 { display: block; } .div2 { display: none; }  @media (max-width: 768px) { .div1 { display: none; } .div2 { display: block;padding: 0px 5px;text-align: center; } .kp-row {margin: 10px 50px;} #input {margin: 15px 0px;}.container-calcu-online {padding: 15px 30px;}.navbar-toggler-icon {filter: invert();}}
.navbar-nav li.nav-item {padding: 1px 30px;} 
.navbar-nav li.nav-item a{font-size: 20px;font-weight: 600;}
main {margin-top: 150px;}
.button {background-color: #fff; color:#000;}
#input-buttons .kp-row {text-align: center;}
/* Ensure to include any existing styles you have */

/* Example of styling circles, you might already have this */
.circle {
  /* Your existing styles for circles */
}

.padding200 {
  padding: 200px;
}

.my-5 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.form {
  width: 100%;
  max-width: 820px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.contact-form {
  background-color: #29264d;
  position: relative;
}

.circle {
  border-radius: 50%;
  background: linear-gradient(135deg, transparent 20%, #3c395e);
  position: absolute;
}

.circle.one {
  width: 130px;
  height: 130px;
  top: 130px;
  right: -40px;
}

.circle.two {
  width: 80px;
  height: 80px;
  top: 10px;
  right: 30px;
}

.contact-form:before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  background-color: #29264d;
  transform: rotate(45deg);
  top: 50px;
  left: -13px;
}

form {
  padding: 2.3rem 2.2rem;
  z-index: 10;
  overflow: hidden;
  position: relative;
}

.title {
  color: #fff;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 0.7rem;
}

.input-container {
  position: relative;
  margin: 1rem 0;
}

.input {
  width: 100%;
  outline: none;
  border: 2px solid #fafafa;
  background: none;
  padding: 0.6rem 1.2rem;
  color: #fff;
  font-weight: 500;
  font-size: 0.95rem;
  letter-spacing: 0.5px;
  border-radius: 5px;
  transition: 0.3s;
}

textarea.input {
  padding: 0.8rem 1.2rem;
  min-height: 150px;
  border-radius: 5px;
  resize: none;
  overflow-y: auto;
}

.input-container label {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  padding: 0 0.4rem;
  color: #fafafa3b;
  font-size: 0.9rem;
  font-weight: 400;
  pointer-events: none;
  /* z-index: 1000; */
  transition: 0.5s;
}

.input-container.textarea label {
  top: 1rem;
  transform: translateY(0);
}

.btn-contact {
  padding: 0.6rem 1.3rem;
  background-color: #fff;
  border: 2px solid #fafafa;
  font-size: 0.95rem;
  color: #29264d;
  line-height: 1;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin: 0;
  width: 100%;
}

.btn-contact:hover {
  background-color: transparent;
  color: #fff;
}

.input-container span {
  position: absolute;
  top: 0;
  left: 25px;
  transform: translateY(-50%);
  font-size: 0.8rem;
  padding: 0 0.4rem;
  color: transparent;
  pointer-events: none;
  z-index: 500;
}

.input-container span:before,
.input-container span:after {
  content: "";
  position: absolute;
  width: 10%;
  opacity: 0;
  transition: 0.3s;
  height: 5px;
  background-color: #29264d;
  top: 50%;
  transform: translateY(-50%);
}

.input-container span:before {
  left: 50%;
}

.input-container span:after {
  right: 50%;
}

.input-container.focus label {
  top: 0;
  transform: translateY(-50%);
  left: 25px;
  font-size: 0.8rem;
}

.input-container.focus span:before,
.input-container.focus span:after {
  width: 50%;
  opacity: 1;
}

.contact-info {
  padding: 2.3rem 2.2rem;
  position: relative;
}

.contact-info .title {
  color: #29264d;
}

.text {
  color: #333;
  margin: 1.5rem 0 2rem 0;
}

.information {
  display: flex;
  color: #555;
  margin: 0.7rem 0;
  align-items: center;
  font-size: 0.95rem;
}

.information i {
  color: #29264d;
}

.icon {
  width: 28px;
  margin-right: 0.7rem;
}

.social-media {
  padding: 2rem 0 0 0;
}

.social-media p {
  color: #333;
}

.social-icons {
  display: flex;
  margin-top: 0.5rem;
}

.social-icons a {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background: linear-gradient(45deg, #29264d, #3c395e);
  color: #fff;
  text-align: center;
  line-height: 35px;
  margin-right: 0.5rem;
  transition: 0.3s;
}

.social-icons a:hover {
  transform: scale(1.05);
}

.contact-info:before {
  content: "";
  position: absolute;
  width: 110px;
  height: 100px;
  border: 22px solid #29264d;
  border-radius: 50%;
  bottom: -77px;
  right: 50px;
  opacity: 0.3;
}

.big-circle {
  position: absolute;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background: linear-gradient(to bottom, #1cd4af, #159b80);
  bottom: 50%;
  right: 50%;
  transform: translate(-40%, 38%);
}

.big-circle:after {
  content: "";
  position: absolute;
  width: 360px;
  height: 360px;
  background-color: #fafafa;
  border-radius: 50%;
  top: calc(50% - 180px);
  left: calc(50% - 180px);
}

.square {
  position: absolute;
  height: 400px;
  top: 50%;
  left: 50%;
  transform: translate(181%, 11%);
  opacity: 0.2;
}

.padding200 {padding: 0px 200px;}
@media (max-width: 850px) {
  .padding200 {padding: 1px !important;}
  .form {
    grid-template-columns: 1fr;
  }

  .contact-info:before {
    bottom: initial;
    top: -75px;
    right: 65px;
    transform: scale(0.95);
  }

  .contact-form:before {
    top: -13px;
    left: initial;
    right: 70px;
  }

  .square {
    transform: translate(140%, 43%);
    height: 350px;
  }

  .big-circle {
    bottom: 75%;
    transform: scale(0.9) translate(-40%, 30%);
    right: 50%;
  }

  .text {
    margin: 1rem 0 1.5rem 0;
  }

  .social-media {
    padding: 1.5rem 0 0 0;
  }
}

@media (max-width: 480px) {
  .padding200 {padding: 1px !important;}

  .container {
    padding: 1.5rem;
  }

  .contact-info:before {
    display: none;
  }

  .square,
  .big-circle {
    display: none;
  }

  form,
  .contact-info {
    padding: 1.7rem 1.6rem;
  }

  .text,
  .information,
  .social-media p {
    font-size: 0.8rem;
  }

  .title {
    font-size: 1.15rem;
  }

  .social-icons a {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .icon {
    width: 23px;
  }

  .input {
    padding: 0.45rem 1.2rem;
  }

  .btn {
    padding: 0.45rem 1.2rem;
  }
}

