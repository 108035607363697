[data-bs-theme="dark"] {
    --bs-body-color: rgb(255, 255, 255) !important; /* Change text color to black */
    --bs-body-bg: black; /* Change background color to dark green */
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
body {background-color: #000 !important;margin: auto;  font-family: "Poppins", sans-serif;}
#input-buttons .kp-calculadora-button {
    display: inline-block;
    vertical-align: top;
    width: 22px;
    height: 19px;
    padding: 10px;
    margin: 5px 1px;
    border-radius: 5px;
    background: #42454a;
    background: -moz-linear-gradient(top, #42454a 0%, #313039 100%);
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, #42454a),
      color-stop(100%, #313039)
    );
    background: -webkit-linear-gradient(top, #42454a 0%, #313039 100%);
    background: -o-linear-gradient(top, #42454a 0%, #313039 100%);
    background: -ms-linear-gradient(top, #42454a 0%, #313039 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#42454a',
        endColorstr='#313039',
        GradientType=0
      );
    background: linear-gradient(to top, #42454a 0%, #313039 100%);
    -webkit-box-shadow: 0 1px 3px 1px #000;
    box-shadow: 0 1px 3px 1px #000;
    font: 20px "Consolas", "Monaco", monospace;
    text-align: center;
    color: rgba(149, 136, 252, 1);
    text-shadow: 0px 1px 1px #000;
    cursor: pointer;
  }
  
  #input-buttons .kp-calculadora-button:active {
   opacity: 0.8;
  }
  
  #input-buttons .kp-num {
    background: #5a656b;
    background: -moz-linear-gradient(to top, #5a656b 0%, #4a5152 100%);
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, #5a656b),
      color-stop(100%, #4a5152)
    );
    background: -webkit-linear-gradient(to top, #5a656b 0%, #4a5152 100%);
    background: -o-linear-gradient(to top, #5a656b 0%, #4a5152 100%);
    background: -ms-linear-gradient(to top, #5a656b 0%, #4a5152 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#5a656b',
        endColorstr='#4a5152',
        GradientType=0
      );
    background: linear-gradient(to top, #5a656b 0%, #4a5152 100%);
  }
  
  #input-buttons .kp-num:active {
    background: linear-gradient(to top, #5a656b 0%, #4a5152 38%);
  }
  
  #input-buttons .kp-eql {
    background: #ff9600;
    background: -moz-linear-gradient(top, #ff9600 0%, #9c4100 100%);
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, #ff9600),
      color-stop(100%, #9c4100)
    );
    background: -webkit-linear-gradient(top, #ff9600 0%, #9c4100 100%);
    background: -o-linear-gradient(top, #ff9600 0%, #9c4100 100%);
    background: -ms-linear-gradient(top, #ff9600 0%, #9c4100 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#ff9600',
        endColorstr='#9c4100',
        GradientType=0
      );
    background: linear-gradient(to top, #ff9600 0%, #9c4100 100%);
  }
  
  #input-buttons .kp-eql:active {
    background: linear-gradient(to top, #ff9600 0%, #9c4100 38%);
  }
  
  #input-buttons .kp-back {
    background: #426184;
    background: -moz-linear-gradient(top, #426184 0%, #29415a 100%);
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, #426184),
      color-stop(100%, #29415a)
    );
    background: -webkit-linear-gradient(top, #426184 0%, #29415a 100%);
    background: -o-linear-gradient(top, #426184 0%, #29415a 100%);
    background: -ms-linear-gradient(top, #426184 0%, #29415a 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(
        startColorstr='#426184',
        endColorstr='#29415a',
        GradientType=0
      );
    background: linear-gradient(to top, #426184 0%, #29415a 100%);
  }
  
  #input-buttons .kp-back:active {
    background: linear-gradient(to top, #426184 0%, #29415a 38%);
  }
  
  #input-buttons .kp-4letters {
    font: bold 15px "Consolas", "Monaco", monospace !important;
    padding: 12px 10px 8px 10px;
  }
  
  #input-buttons .kp-5letters {
    padding: 12px 12px 8px 8px;
    font: bold 15px "Consolas", "Monaco", monospace !important;
  }
  
  #input-buttons .kp-calculadora-button,
  #input-buttons .kp-4letters {
    color: #fff;
    width: 65px;
    height: 50px;
  }

#input-buttons .kp-calculadora-button, #input-buttons .kp-4letters { color: #fff;width: 65px;height: 50px;}
#input{width: 100%; padding: 25px; margin: 15px; background-color: rgba(37, 37, 37, 1); border-radius: 50px;border-top:2px solid #595959 ;border-left:2px solid #595959 ;color: #fff;}
#input-buttons .kp-num {background: #fff; color: #000; }
#input-buttons .kp-num.kp-2 {background: #515151;color: #fff;}
#input-buttons .kp-eql { background: #1D4EC7;}
#input-buttons .kp-back {background:rgba(255, 0, 0, 0.33) ; }
.navbar-theme-color {background-color:#29264D ;}
.navbar-theme-color a {color:#fff !important;}
.navbar-theme-color a:active {color: rgb(223, 223, 223) !important;}
.box-funtion-3 {width: 311px; height: 119; padding: 25px 20px; background: #252525;margin: 15px;}
.box-funtion-3 p { font-weight: 500; font-size: 16px; line-height: 24px;margin-bottom: 1px !important;color: #FF6969;}
.container-calcu-online {padding: 0px 150px;}
.formula_para p{color:#fff;text-align: center; font-weight: 500; line-height: 30px; margin-bottom: 2px;font-size: 20px;}
@media (max-width: 768px) { .div1 { display: none; } .div2 { display: block;padding: 0px 10px;text-align: center; }}
.div1 { display: block; } .div2 { display: none; }  @media (max-width: 768px) { .div1 { display: none; } .div2 { display: block;padding: 0px 5px;text-align: center; } .kp-row {margin: 10px 50px;} #input {margin: 15px 0px;}.container-calcu-online {padding: 15px 30px;}.navbar-toggler-icon {filter: invert();}}
.navbar-nav li.nav-item {padding: 1px 30px;} 
.navbar-nav li.nav-item a{font-size: 20px;font-weight: 600;}
main {margin-top: 150px;}
.button {background-color: #fff; color:#000;}
#input-buttons .kp-row {text-align: center;}